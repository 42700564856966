import Vue from 'vue';
import {
  Button,
  NavBar,
  Collapse,
  CollapseItem,
  Grid,
  GridItem,
  Tab,
  Tabs,
  Divider,
  List,
  Cell,
  Col,
  Row,
  Field,
  Dialog,
  NoticeBar,
  Swipe,
  SwipeItem,
  Toast,
  Image,
  Form,
  Uploader,
  Popup 
} from 'vant';
Vue.use(Popup);
Vue.use(Uploader);
Vue.use(Form);
Vue.use(Toast);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Button);
Vue.use(NavBar);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Divider);
Vue.use(List);
Vue.use(Cell);
Vue.use(Col);
Vue.use(Row);
Vue.use(Field);
Vue.use(Dialog);
Vue.use(NoticeBar);
Vue.use(Image);