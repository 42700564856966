import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import "vant/lib/index.css";
import "@/plugin/vant";
import "@/rem";
// 矢量图
import moment from "moment";
Vue.prototype.$moment = moment;
// main.js 文件
import VueLazyload from "vue-lazyload";
// Vue.use(VueLazyload) //无配置项
// 配置项
Vue.use(VueLazyload, {
  preLoad: 1.3, //预加载的宽高比
  loading: require("./static/images/cases/loading.png"), //图片加载状态下显示的图片
  error: require("./static/images/cases/error.png"), //图片加载失败时显示的图片
  attempt: 1, // 加载错误后最大尝试次数
});
// img元素上使用v-lazy="src";<img v-lazy="showImage"/>
Vue.config.productionTip = false;
import "@/api/homeapi";

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
