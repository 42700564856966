import Vue from 'vue';
import Vuex from 'vuex';
import home from '@/store/modules/home'

Vue.use(Vuex);

const actions = {}
const mutations = {}
const state = {
}
const getters = {}

export default new Vuex.Store({
  actions,
  mutations,
  state,
  getters,
  //在配置大store中所有配置的同时，还要配置模块化的小store
  modules: {
    home
  }
})