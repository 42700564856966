import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

//!路由懒加载
const UserAgreementAndroid = () => import("@/pages/UserAgreementAndroid");
const PrivacyAndroid = () => import("@/pages/PrivacyAndroid");
const Newbie = () => import("@/pages/Newbie");
const NewbieDetail = () => import("@/pages/NewbieDetail");
const ProtocolProcess = () => import("@/pages/ProtocolProcess");
const VerificationReport = () => import("@/pages/VerificationReport");
// const VerificationReport2 = () => import("@/pages/VerificationReport2");
const BlacklistExposure = () => import("@/pages/BlacklistExposure");
const CustomerServiceVer = () => import("@/pages/CustomerServiceVer");
const ExposureDetails = () => import("@/pages/ExposureDetails");
const newLitigationDetails = () => import("@/pages/LeaveTheGame/newLitigationDetails");
const newExposureDetails = () => import("@/pages/LeaveTheGame/newExposureDetails");
const Security = () => import("@/pages/Security");
const LoveRecyc = () => import("@/pages/LoveRecyc");
const CompensateForList = () => import("@/pages/CompensateForList");
const LitigationDocuments = () => import("@/pages/LitigationDocuments");
const RecoveryCase = () => import("@/pages/RecoveryCase");
const TransactionReport = () => import("@/pages/TransactionReport");
const BlacklistQuery = () => import("@/pages/BlacklistQuery");
const LitigationDetails = () => import("@/pages/LitigationDetails");
const LitigationDocumentsDeatil = () =>
  import("@/pages/LitigationDocumentsDeatil");
const RecoveryCaseDetail = () => import("@/pages/RecoveryCaseDetail");
const ModifyRealName = () => import("@/pages/ModifyRealName");
const BlankPage = () => import("@/pages/BlankPage");
const PublicAgreement = () => import("@/pages/PublicAgreement");
const Privacy = () => import("@/pages/Privacy");
const NewSecurity = () => import("@/pages/NewSecurity");
const ImgContainer = () => import("@/pages/ImgContainer");
const Technology = () => import("@/pages/Technology");
const DownloadPage = () => import("@/pages/DownloadPage");
//!配置路由
const router = new VueRouter({
  mode: "history",
  routes: [
    {
      name: "UserAgreementAndroid",
      path: "/useragreementandroid",
      component: UserAgreementAndroid,
    },
    {
      path: "/",
      component: UserAgreementAndroid,
    },
    {
      name: "PrivacyAndroid",
      path: "/privacyandroid",
      component: PrivacyAndroid,
    },
    {
      name: "Newbie",
      path: "/newbie",
      component: Newbie,
      meta: {
        keepAlive: true,
      },
    },
    {
      name: "NewbieDetail",
      path: "/newbiedetail",
      component: NewbieDetail,
    },
    {
      name: "ProtocolProcess",
      path: "/protocolprocess",
      component: ProtocolProcess,
    },
    {
      name: "VerificationReport",
      path: "/verificationreport",
      component: VerificationReport,
    },
    // {
    //   name: "VerificationReport2",
    //   path: "/verificationreport2",
    //   component: VerificationReport2,
    // },
    {
      name: "BlacklistExposure",
      path: "/blacklistxposure",
      meta: {
        keepAlive: true,
      },
      component: BlacklistExposure,
    },
    {
      name: "CustomerServiceVer",
      path: "/customerservicever",
      component: CustomerServiceVer,
    },
    {
      name: "ExposureDetails",
      path: "/exposuredetails",
      component: ExposureDetails,
    },
    {
      name: "newExposureDetails",
      path: "/newExposureDetails",
      component: newExposureDetails,
    },
    {
      name: "newLitigationDetails",
      path: "/newLitigationDetails",
      component: newLitigationDetails,
    },
    {
      name: "Security",
      path: "/security",
      meta: {
        keepAlive: true,
      },
      component: Security,
    },
    {
      name: "LoveRecyc",
      path: "/loveRecyc",
      meta: {
        keepAlive: true,
      },
      component: LoveRecyc,
    },
    {
      name: "NewSecurity",
      path: "/newsecurity",
      component: NewSecurity,
    },
    {
      name: "ImgContainer",
      path: "/imgcontainer",
      component: ImgContainer,
    },
    {
      name: "CompensateForList",
      path: "/compensateforlist",
      component: CompensateForList,
    },
    {
      name: "LitigationDocuments",
      path: "/litigationdocuments",
      meta: {
        keepAlive: true,
      },
      component: LitigationDocuments,
    },
    {
      name: "RecoveryCase",
      path: "/recoverycase",
      component: RecoveryCase,
    },
    {
      name: "TransactionReport",
      path: "/transactionreport",
      component: TransactionReport,
    },
    {
      name: "BlacklistQuery",
      path: "/blacklistquery",
      component: BlacklistQuery,
    },
    {
      name: "LitigationDetails",
      path: "/litigationdetails",
      component: LitigationDetails,
    },
    {
      name: "LitigationDocumentsDeatil",
      path: "/litigationdocumentsdeatil",
      component: LitigationDocumentsDeatil,
    },
    {
      name: "RecoveryCaseDetail",
      path: "/recoverycasedetail",
      meta: {
        keepAlive: true,
      },
      component: RecoveryCaseDetail,
    },
    {
      name: "ModifyRealName",
      path: "/modifyrealname",
      component: ModifyRealName,
    },
    {
      name: "BlankPage",
      path: "/blankpage",
      component: BlankPage,
    },
    {
      name: "PublicAgreement",
      path: "/publicagreement",
      component: PublicAgreement,
    },
    {
      name: "Privacy",
      path: "/privacy",
      component: Privacy,
    },
    {
      name: "Technology",
      path: "/technology",
      component: Technology,
    },
    {
      name: "SincereSelling",
      path: "/sincereselling",
      component: () => import("@/pages/SincereSelling"),
    },
    {
      name: "SincereSellingCases",
      path: "/sinceresellingcases",
      component: () => import("@/pages/SincereSellingCases"),
    },
    // 保证金退还
    {
      name: "RefundDeposit",
      path: "/refunddeposit",
      component: () => import("@/pages/RefundDeposit"),
    },
    {
      name: "ConsultationDetails",
      path: "/consultationDetails",
      component: () => import("@/pages/ConsultationDetails"),
    },
    {
      name: "GameTradingTechnology",
      path: "/gametradingtechnology",
      component: () => import("@/pages/GameTradingTechnology"),
    },
    // 虚贝下载页面
    {
      name: "TravelTransaction",
      path: "/traveltransaction",
      component: () => import("@/pages/TravelTransaction"),
    },
    {
      name: "Spielkonto",
      path: "/spielkonto",
      component: () => import("@/pages/Spielkonto"),
    },
    {
      name: "AccountCat",
      path: "/accountcat",
      component: () => import("@/pages/AccountCat"),
    },
    {
      name: "TechnicalConfig",
      path: "/technicalConfig",
      component: () => import("@/pages/TechnicalConfig"),
    },
    {
      name: "TitleImagePublic",
      path: "/titleImagePublic",
      component: () => import("@/pages/TitleImagePublic"),
    },
    {
      name: "QuickSale",
      path: "/quicksale",
      component: () => import("@/pages/QuickSale"),
    },
    // 新下载宣传页
    {
      name: "DownloadPage",
      path: "/downloadpage",
      component: () => import("@/pages/DownloadPage"),
    },
    {
      name: "newsecurity",
      path: "/leavethegame/newsecurity",
      component: () => import("@/pages/LeaveTheGame/NewSecurity"),
    },
    //追回案例
    {
      name: "RecoveryCases",
      path: "/leavethegame/RecoveryCases",
      component: () => import("@/pages/LeaveTheGame/RecoveryCases"),
    },
    //包赔案例
    {
      name: "CompensationCases",
      path: "/leavethegame/CompensationCases",
      component: () => import("@/pages/LeaveTheGame/CompensationCases"),
    },
    {
      name: "securityCenter",
      path: "/leavethegame/securityCenter",
      component: () => import("@/pages/LeaveTheGame/SecurityCenter"),
    },
    // {
    //   name: "newExposureDetails",
    //   path: "/leavethegame/newExposureDetails",
    //   component: () => import("@/pages/LeaveTheGame/newExposureDetails"),
    // },
    // {
    //   name: "newLitigationDetails",
    //   path: "/leavethegame/newLitigationDetails",
    //   component: () => import("@/pages/LeaveTheGame/newLitigationDetails"),
    // }
  ],
});

export default router;
