import myAxios from "@/utils/myRequest";
// 请求规则配置
export const getConfig = (configContent) => {
  let formdata = new FormData();
  for (let key in configContent) {
    formdata.append(key, configContent[key]);
  }
  return myAxios({
    url: `/api/getConfig`,
    method: "post",
    data: formdata,
    header: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};
// /api/admin/newUserProblemList 新手上路
export const getNewUserProblemList = () => {
  return myAxios({
    url: `/api/admin/newUserProblemList`,
    method: "post",
  });
};
// /api/im/bulletDetail
export const getBulletDetail = (configContent) => {
  let formdata = new FormData();
  for (let key in configContent) {
    formdata.append(key, configContent[key]);
  }
  return myAxios({
    url: `/api/im/bulletDetail`,
    method: "post",
    data: formdata,
    header: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};
// /api/wzryAccountDetail 王者荣耀账号详情
export const getWzryAccountDetail = (configContent) => {
  return myAxios({
    url: `/api/wzryAccountDetail`,
    method: "post",
    data: configContent,
  });
};
export const getUploadFile = (configContent) => {
  return myAxios({
    url: `api/uploadFile`,
    method: "post",
    data: configContent,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
// /api/userRevamp
export const getUserRevamp = (configContent) => {
  let formdata = new FormData();
  for (let key in configContent) {
    formdata.append(key, configContent[key]);
  }
  return myAxios({
    url: `api/userRevamp`,
    method: "post",
    data: formdata,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
