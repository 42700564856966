//模块化的store -- 小store
// import {requestOrderId} from "@/api/pay"
const actions = {

};
const mutations = {
  SET_LIBRARYINFO(state, value) {
    state.libraryInfo = value;
  }
};
const state = {
  libraryInfo: {
    channel: "",
    equipment_id: "",
    platform: "",
    token: "",
    versions: "",
    library_ids: ""
  },
};
const getters = {};
export default {
  actions,
  mutations,
  state,
  getters,
  // namespaced: true
}