//request.js专门用来配置axios
//1. npm 下载axios 并引入
import axios from 'axios'
import store from '@/store'
//使用token专用

import Nprogress from 'nprogress'
import 'nprogress/nprogress.css'

//2.因为我们要给axios配置一些配置项，但是并不是所有的axios都需要，所以可以创建自己的axios实例
const myAxios = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 10000
})

//3.配置当前axios的拦截器
myAxios.interceptors.request.use((config) => {
  // config.headers.authority = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vYXBpLm9odW9rZWppLmNvbS9hcGkvbWFvdGFpL3VzZXJMb2dpbiIsImlhdCI6MTY2MDE4MDUyMiwiZXhwIjoxNjYwNzg1MzIyLCJuYmYiOjE2NjAxODA1MjIsImp0aSI6Im9KNTFzNFpCWTRKUjZyUGQiLCJzdWIiOjMwNjkzLCJwcnYiOiI4NDk0NjQyN2VlMmVkMTE5ODRjMTQxMDY3ZDU0N2I2ZTMyZDZkN2Y5IiwibW9iaWxlIjoiMTc1Mzk4NTE4NzAifQ.WYfdymeBuCsvin7Uk9tIM7IX-BgLKCjgOOb6hHhk5zc';
  // const libraryInfo = JSON.parse(localStorage.getItem("libraryInfo_key"))
  // config.headers.authority = libraryInfo.token;
  return config
}, (err) => {
  return Promise.reject(err)
})

// 添加响应拦截器
myAxios.interceptors.response.use((response) => {
  return response.data;
}, function (error) {
  return Promise.reject(error);
});


export default myAxios;